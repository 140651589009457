import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"
const ComponentName = ({data}) => {
  const { blog } = data
  return (
    <Layout>
      <SEO title={blog.frontmatter.title} description={blog.frontmatter.description} />
      <section className='blog-template'>
        <div className='section-center'>
          <h1>{blog.frontmatter.title}</h1>
          <article className='blog-content'>
            <ReactMarkdown source={blog.rawMarkdownBody} />
          </article>
          <Link to='/news/' className='btn center-btn'>
            All news
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($slug: String)
  {
    blog: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      content
      title
      description
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    rawMarkdownBody
  }
  }
`

export default ComponentName
